<script setup>
const props = defineProps({
    modelValue: {
        type: [ String, Number ],
        default: ''
    },
    outlined: {
        type: Boolean,
        default: false
    },
    letterCount: {
        type: [ Number, null ],
        default: null
    }
})
const count = ref(0)
const emit = defineEmits([ 'update:modelValue' ])
const inputValue = computed({
    get() {
        return props.modelValue
    },

    set(value) {
        emit('update:modelValue', value)
    }
})


const mode = inject('mode', ref('light'))
const extraInputStyle = computed(() => {
    let classes = ''

    if (props.outlined) {
        classes += ' tw-border-[1px] tw-border-slate-200 focus:tw-border-blue-wc'
    } else {
        classes += ' tw-border-[2px] tw-border-transparent focus:tw-border-b-blue-wc'
    }

    if (mode.value == 'dark') {
        classes = classes + ' tw-bg-darkmode tw-text-slate-200'
    } else {
        classes = classes + ' tw-bg-slate-100 tw-text-slate-500'
    }

    return classes
})


const letterCountStyle = ref('tw-text-slate-500')
watch(inputValue, (newValue) => {
    count.value = newValue.length

    if (props.letterCount) {
        if (newValue.length > props.letterCount) {
            letterCountStyle.value = 'tw-text-red-500'
        } else {
            letterCountStyle.value = 'tw-text-slate-500'
        }
    }
})
</script>

<template>
    <section class="tw-w-full tw-flex tw-flex-wrap">
        <textarea
            v-model="inputValue"
            class="tw-inline-flex tw-w-full tw-p-3 tw-outline-none tw-rounded-md 
                        tw-text-[16px]" 
            :class="extraInputStyle"
        />
        <span
            v-if="letterCount"
            class="tw-flex tw-py-2 tw-w-full tw-justify-end"
            :class="letterCountStyle"
        >
            {{ count }}/{{ letterCount }}
        </span>
    </section>
</template>